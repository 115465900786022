import * as React from 'react';
import { styled } from '@linaria/react';

import { HEADER_WIDTH } from '@/constants';

export interface Props {
  as?: keyof JSX.IntrinsicElements | React.ComponentType<any>;
  maxWidth?: number | string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
}

function MaxWidthWrapper({
  as = 'div',
  maxWidth,
  style = {},
  children,
  ...delegated
}: Props) {
  if (typeof maxWidth === 'undefined') {
    maxWidth = `${HEADER_WIDTH}rem`;
  } else if (typeof maxWidth === 'number') {
    // Historically, I’ve specified the maximum width in pixels, and I'm too lazy to find and update all of the spots where I've specified them. We’ll divide by 16 to convert to rems:
    maxWidth = `${maxWidth / 16}rem`;
  }

  return (
    <Wrapper
      as={as}
      style={{ '--max-width': maxWidth, ...style }}
      {...delegated}
    >
      {children}
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  max-width: var(--max-width);
  margin-inline: auto;
  padding-inline: var(--viewport-padding);
`;

export default MaxWidthWrapper;

import React from 'react';
import { styled } from '@linaria/react';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  duration?: number;
  delay?: number;
  children: React.ReactNode;
}

const FadeIn = ({
  duration = 333,
  delay = 0,
  children,
  ...delegated
}: Props) => {
  return (
    <Wrapper
      {...delegated}
      style={{
        ...(delegated.style || {}),
        animationDuration: duration + 'ms',
        animationDelay: delay + 'ms',
      }}
    >
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  @media (prefers-reduced-motion: no-preference) {
    animation-name: fadeIn;
    animation-fill-mode: backwards;
  }
`;

export default FadeIn;

import { useState, useEffect } from 'react';

export interface State {
  x: number | null;
  y: number | null;
}

function useMousePosition() {
  const [mousePosition, setMousePosition] = useState<State>({
    x: null,
    y: null,
  });

  const updateMousePosition = (ev: MouseEvent) => {
    setMousePosition({ x: ev.clientX, y: ev.clientY });
  };

  useEffect(() => {
    window.addEventListener('mousemove', updateMousePosition);

    return () =>
      window.removeEventListener('mousemove', updateMousePosition);
  }, []);

  return mousePosition;
}

export default useMousePosition;
